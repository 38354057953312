import * as moment from 'moment-timezone';

export class Globals {
  user: any;
  getUser() {
    let data = localStorage.getItem('partner_user');
    if (data != null) {
      this.user = JSON.parse(data);
    } else {
      this.user = null;
    }

    return this.user;
  }
  getImageUrlBucket() {
    //return 'https://craddle.com/SaharaGo/File/';
  }
  _getFileType(filename: any = ''): string {
    let ext = '';
    let imageExtArr = [
      'bmp',
      'cod',
      'gif',
      'ief',
      'jpe',
      'jpeg',
      'jpg',
      'jfif',
      'svg',
      'tif',
      'tiff',
      'ras',
      'cmx',
      'ico',
      'pnm',
      'pbm',
      'pgm',
      'ppm',
      'rgb',
      'xbm',
      'xpm',
      'xwd',
      'png',
      'webp',
    ];
    let videoExtArr = [
      'mp2',
      'mpa',
      'mpe',
      'mpeg',
      'mpg',
      'mpv2',
      'mp4',
      'mov',
      'qt',
      'lsf',
      'lsx',
      'asf',
      'asr',
      'asx',
      'avi',
      'movie',
    ];
    let docExtArr = ['doc', 'docx'];
    let pdfExtArr = ['pdf'];

    if (filename == '') {
      return ext;
    }
    ext = filename.split('.').pop().toLowerCase();
    if (imageExtArr.includes(ext)) {
      return 'image';
    } else if (videoExtArr.includes(ext)) {
      return 'video';
    } else if (docExtArr.includes(ext)) {
      return 'document';
    } else if (pdfExtArr.includes(ext)) {
      return 'pdf';
    } else {
      return '';
    }
  }
  public static get validImageTypes(): Array<any> {
    return [
      'image/gif',
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/webp',
      'application/pdf',
    ];
  }

  _getLangData(filename: any = ''): string {
    let langArrayData: any = [];
    langArrayData['Category_us'] = 'Category';
    langArrayData['Category_fr'] = 'Catégorie';
    langArrayData['SubCategory_us'] = 'SubCategory';
    langArrayData['SubCategory_fr'] = 'Sous-catégorie';
    langArrayData['Products_us'] = 'Products';
    langArrayData['Products_fr'] = 'Des produits';
    langArrayData['User Name_us'] = 'User Name';
    langArrayData['User Name_fr'] = "Nom d'utilisateur";
    langArrayData['Address_us'] = 'Address';
    langArrayData['Address_fr'] = 'Adresse';
    langArrayData['Type_us'] = 'Type';
    langArrayData['Type_fr'] = 'Type';
    langArrayData['DOB_us'] = 'DOB';
    langArrayData['DOB_fr'] = 'Date de naissance';
    langArrayData['Assigned Agent_us'] = 'Assigned Agent';
    langArrayData['Assigned Agent_fr'] = 'Conseiller';
    langArrayData['Country_us'] = 'Country';
    langArrayData['Country_fr'] = 'Pays';
    langArrayData['Status_us'] = 'Status';
    langArrayData['Status_fr'] = 'Etat';
    langArrayData['Client_fr'] = 'Client';
    langArrayData['Client_en'] = 'Client';
    langArrayData['Task Type_fr'] = 'Type';
    langArrayData['Task Type_en'] = 'Type';
    langArrayData['Action_us'] = 'Action';
    langArrayData['Action_fr'] = 'Action';
    langArrayData['Lead ID/Offer ID_us'] = 'Lead Id/OfferId';
    langArrayData['Lead ID/Offer ID_fr'] =
      'Identifiant du prospect / Identifiant de loffre';
    langArrayData['Customer_us'] = 'Customer';
    langArrayData['Customer_fr'] = 'client';
    langArrayData['Company/Product_en'] = 'Company/Product';
    langArrayData['Company/Product_fr'] = 'Entreprise/Produit';
    langArrayData['Claim ID_en'] = 'Claim ID';
    langArrayData['Claim ID_fr'] = 'ID du sinistre';
    langArrayData['Creation Date_fr'] = 'Date de création';
    langArrayData['Creation Date_en'] = 'Creation date';
    langArrayData['Last Update Date_fr'] = 'Mise à jour';
    langArrayData['Last Update Date_en'] = 'Last updated';
    langArrayData['Agent_us'] = 'Agent';
    langArrayData['Agent_fr'] = 'Agent';
    langArrayData['Added At_us'] = 'Added At';
    langArrayData['Added At_fr'] = 'Ajouté à';
    langArrayData['Email/Phone_us'] = 'Email/Phone';
    langArrayData['Email/Phone_fr'] = 'Courriel/Téléphone';
    langArrayData['Cat/Subcat_us'] = 'Cat/Subcat';
    langArrayData['Cat/Subcat_fr'] = 'Chat/sous-chat';
    langArrayData['Lead ID_us'] = 'Lead Id';
    langArrayData['Lead ID_fr'] = 'Identifiant du prospect';
    langArrayData['Client/Relation_us'] = 'Client/Relation';
    langArrayData['Client/Relation_fr'] = 'Client/Relation';
    langArrayData['Relation_us'] = 'Relation';
    langArrayData['Relation_fr'] = 'Relation';
    langArrayData['Date_us'] = 'Date';
    langArrayData['Date_fr'] = 'Date';
    langArrayData['Request Type_us'] = 'Request Type';
    langArrayData['Request Type_fr'] = 'type de demande';
    langArrayData['Policy ID_us'] = 'Policy ID';
    langArrayData['Policy ID_fr'] = 'ID Police';
    langArrayData['User Name/Type_us'] = 'User Name/Type';
    langArrayData['User Name/Type_fr'] = "Nom d'utilisateur/type";
    langArrayData['Start Date/End Date_us'] = 'Start Date/End Date';
    langArrayData['Start Date/End Date_fr'] = 'Date de début/Date de fin';
    langArrayData['Enquired On_us'] = 'Enquired On';
    langArrayData['Enquired On_fr'] = 'Interrogé sur';
    langArrayData['Sub Category_us'] = 'Sub Category';
    langArrayData['Sub Category_fr'] = 'Sous-catégorie';
    langArrayData['Agent Reserve %_us'] = 'Agent Reserve %';
    langArrayData['Agent Reserve %_fr'] = "% de réserve d'agent";
    langArrayData['Reserve %_us'] = 'Reserve rate';
    langArrayData['Reserve %_fr'] = 'Taux de réserve';
    langArrayData['Commission %_us'] = 'Commission Rate';
    langArrayData['Commission %_fr'] = 'Taux de commission';
    langArrayData['Agent Commission %_us'] = 'Agent Commission %';
    langArrayData['Agent Commission %_fr'] = "% de commission d'agent";
    langArrayData['Added/Update On_us'] = 'Added/Update On';
    langArrayData['Added/Update On_fr'] = 'Ajouté/mis à jour le';
    langArrayData['Company Reserve %_us'] = 'Company Reserve %';
    langArrayData['Company Reserve %_fr'] = "% de réserve de l'entreprise";
    langArrayData['Agent Name_us'] = 'Agent Name';
    langArrayData['Agent Name_fr'] = "Nom d'agent";
    langArrayData['Added On_us'] = 'Added On';
    langArrayData['Added On_fr'] = 'Ajouté le';
    langArrayData['Client Name_us'] = 'Client Name';
    langArrayData['Client Name_fr'] = 'Nom du client';
    langArrayData['Start Date_us'] = 'Start Date';
    langArrayData['Start Date_fr'] = 'Date de début';
    langArrayData['End Date_us'] = 'End Date';
    langArrayData['End Date_fr'] = 'Date de fin';
    langArrayData['Member_us'] = 'Member';
    langArrayData['Member_fr'] = 'Membre';
    langArrayData['Member/Relation_us'] = 'Member/Relation';
    langArrayData['Member/Relation_fr'] = 'Membre/Relation';
    langArrayData['Last File Added On_us'] = 'Last File Added On';
    langArrayData['Last File Added On_fr'] = 'Dernier fichier ajouté le';
    langArrayData['File Name_us'] = 'File Name';
    langArrayData['File Name_fr'] = 'Nom de fichier';
    langArrayData['Uploaded On_en'] = 'Uploaded On';
    langArrayData['Uploaded On_fr'] = 'Téléchargé le';
    langArrayData['Document Type_en'] = 'Document type';
    langArrayData['Document Type_fr'] = 'Type de document';
    langArrayData['Upload On_us'] = 'Upload On';
    langArrayData['Upload On_fr'] = 'Télécharger sur';
    langArrayData['Added/Updated On_us'] = 'Added/Updated On';
    langArrayData['Added/Updated On_fr'] = 'Ajouté/mis à jour le';
    langArrayData['Insurance Company_us'] = 'Insurance Company';
    langArrayData['Insurance Company_fr'] = "Compagnie d'assurance";
    langArrayData['Phone number_fr'] = 'N° de téléphone';
    langArrayData['Phone number_en'] = 'Phone number';
    langArrayData['Insurance Product_us'] = 'Insurance Product';
    langArrayData['Insurance Product_fr'] = 'Produit';
    langArrayData['Insurance Coverage_us'] = 'Insurance Coverage';
    langArrayData['Insurance Coverage_fr'] = 'Couverture';
    langArrayData['Insurance Type_en'] = 'Insurance type';
    langArrayData['Insurance Type_fr'] = "Type d'assurance";
    langArrayData['Insurance Branch_en'] = 'Branch';
    langArrayData['Insurance Branch_fr'] = 'Branche';

    langArrayData['Task_us'] = 'Task';
    langArrayData['Task_fr'] = 'Tâche';
    langArrayData['Meeting Date_us'] = 'Meeting Date';
    langArrayData['Meeting Date_fr'] = 'Date de la réunion';
    langArrayData['Meeting Hour_us'] = 'Meeting Hour';
    langArrayData['Meeting Hour_fr'] = 'Heure de réunion';

    langArrayData['Joiness_us'] = 'Joinees';
    langArrayData['Joiness_fr'] = 'Joindre';

    langArrayData['S.N_us'] = 'S.N';
    langArrayData['S.N_fr'] = 'S.N.';
    langArrayData['Name_us'] = 'Name';
    langArrayData['Namefr'] = 'Nom';
    langArrayData['Email_us'] = 'Email';
    langArrayData['Email_fr'] = 'E-mail';
    langArrayData['Role_us'] = 'Role';
    langArrayData['Role_fr'] = 'Rôle';

    langArrayData['Customer/Policy_us'] = 'Customer/Policy';
    langArrayData['Customer/Policy_fr'] = 'Client/Politique';
    langArrayData['Gross Income_us'] = 'Gross Income';
    langArrayData['Gross Income_fr'] = 'Revenu brut';
    langArrayData['Partner Reserve_us'] = 'Partner Reserve';
    langArrayData['Partner Reserve_fr'] = 'Réserve partenaire';
    langArrayData['Agent Comm./Reserve_us'] = 'Agent Comm./Reserve';
    langArrayData['Agent Comm./Reserve_fr'] = 'Commission/Réserve agent';
    langArrayData['Subscriber Email_us'] = 'Subscriber Email';
    langArrayData['Subscriber Email_fr'] = "E-mail de l'abonné";
    langArrayData['Created By_us'] = 'Created By';
    langArrayData['Created By_fr'] = 'Créé par';
    langArrayData['Template Name_us'] = 'Template Name';
    langArrayData['Template Name_fr'] = 'Nom du modèle';
    langArrayData['Total Income_us'] = 'Total Income';
    langArrayData['Total Income_fr'] = 'Revenu total';
    langArrayData['Total Amount_us'] = 'Total Amount';
    langArrayData['Total Amount_fr'] = 'Montant total';
    langArrayData['Contract Signed_us'] = 'Contract Signed';
    langArrayData['Contract Signed_fr'] = 'Contrat signé';
    langArrayData['Proposal Shared_us'] = 'Proposal Shared';
    langArrayData['Proposal Shared_fr'] = 'Proposition partagée';
    langArrayData['Customers_us'] = 'Customers';
    langArrayData['Customers_fr'] = 'Clients';
    langArrayData['Policy Id_us'] = 'Policy Id';
    langArrayData['Policy Id_fr'] = 'ID Police';

    langArrayData['Approved_us'] = 'Approved';
    langArrayData['Approved_fr'] = 'Approuvé';
    langArrayData['Pending_us'] = 'Pending';
    langArrayData['Pending_fr'] = 'En attente';
    langArrayData['Blocked_us'] = 'Blocked';
    langArrayData['Blocked_fr'] = 'Bloqué';
    langArrayData['Active_us'] = 'Active';
    langArrayData['Active_fr'] = 'Actif';
    langArrayData['ACCEPTED_us'] = 'Accepted';
    langArrayData['ACCEPTED_fr'] = 'Accepté';
    langArrayData['SAVED_us'] = 'Saved';
    langArrayData['SAVED_fr'] = 'Enregistré';
    langArrayData['POLICY CREATED_us'] = 'Policy Created';
    langArrayData['POLICY CREATED_fr'] = 'Politique créée';
    langArrayData['REJECTED_us'] = 'Rejected';
    langArrayData['REJECTED_fr'] = 'Rejeté';
    langArrayData['INACTIVE_us'] = 'Inactive';
    langArrayData['INACTIVE_fr'] = 'Inactif';
    langArrayData['CLOSED_us'] = 'Closed';
    langArrayData['CLOSED_fr'] = 'Fermé';
    langArrayData['Completed_us'] = 'Completed';
    langArrayData['Completed_fr'] = 'Complété';
    langArrayData['Company/Branch_en'] = 'Company/Branch';
    langArrayData['Company/Branch_fr'] = 'Compagnie / Branche';
    langArrayData['Start/End Date_en'] = 'Start/End Date';
    langArrayData['Start/End Date_fr'] = 'Date de début/fin';
    langArrayData['Partner Reserve_en'] = 'Partner Reserve';
    langArrayData['Partner Reserve_fr'] = 'Réserve partenaire';
    langArrayData['Partner Commission/Reserve_en'] =
      'Partner Commission/Reserve';
    langArrayData['Partner Commission/Reserve_fr'] =
      'Commission/Réserve partenaire';
    langArrayData['Agent Commision/Reserve_en'] = 'Agent Commision/Reserve';
    langArrayData['Agent Commision/Reserve_fr'] =
      'Commission/Réserve Conseiller';
    langArrayData['Reason_fr'] = 'Cause';
    langArrayData['Reason_en'] = 'Reason';
    langArrayData['Remark_fr'] = 'Remarque';
    langArrayData['Remark_en'] = 'Remark';
    langArrayData['Modification Date_fr'] = 'Date de modification';
    langArrayData['Modification Date_en'] = 'Modification date';
    langArrayData['Partner Commission Rate_fr'] = 'Taux de commission';
    langArrayData['Partner Commission Rate_en'] = 'Commission rate';
    langArrayData['Partner Reserve_fr'] = 'Taux de reserve';
    langArrayData['Partner Reserve_en'] = 'reserve rate';
    return langArrayData;
  }
}

export const toUTCWithoutChangingTime = (dateString: string) => {
  const localDate = moment(dateString);

  // Create a new moment object in UTC with the same local time
  const utcDate = moment.utc({
    year: localDate.year(),
    month: localDate.month(),
    date: localDate.date(),
    hour: localDate.hour(),
    minute: localDate.minute(),
    second: localDate.second(),
  });

  return utcDate;
};
