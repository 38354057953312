import {
  APP_BASE_HREF,
  CommonModule,
  DatePipe,
  PlatformLocation,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { customerFilter, SplitPipe } from '../_pipes/filter.pipe';
import { ReplaceUnderscorePipe } from '../_pipes/replace-underscore-pipe';
import { SafeHtmlPipe } from '../safehtml.pipe';
import { FileDragDropDirective } from './_directives/file-drag-drop.directive';
import { CustomerNavComponent } from './components/customer-nav/customer-nav.component';
import { DataAnalysisNavComponent } from './components/data-analysis-nav/data-analysis-nav.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { SelectAllCheckboxComponent } from './components/select-all-checkbox/select-all-checkbox.component';
import { SelectAllMatCheckboxComponent } from './components/select-all-mat-checkbox/select-all-mat-checkbox.component';
import { SharedRoutingModule } from './shared-routing.module';
import { PdfViewerModalComponent } from './components/pdf-viewer-modal/pdf-viewer-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GenerateUserPasswordModalComponent } from './components/generate-user-password-modal/generate-user-password-modal.component';
import { UpdateDocumentModalComponent } from './components/update-document-modal/update-document-modal.component';
import { DocumentRequestModalComponent } from './components/document-request-modal/document-request-modal.component';
import { DocumentUploadModalComponent } from './components/document-upload-modal/document-upload-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeadFormComponent } from './components/lead-form/lead-form.component';
import { AnimalFormComponent } from './components/lead-form/animal-form/animal-form.component';
import { BuildingFormComponent } from './components/lead-form/building-form/building-form.component';
import { CentralisationFormComponent } from './components/lead-form/centralisation-form/centralisation-form.component';
import { ConstructionFormComponent } from './components/lead-form/construction-form/construction-form.component';
import { HealthFormComponent } from './components/lead-form/health-form/health-form.component';
import { LegalProtectionFormComponent } from './components/lead-form/legal-protection-form/legal-protection-form.component';
import { RcHouseholdFormComponent } from './components/lead-form/rc-household-form/rc-household-form.component';
import { ThirdPillarFormComponent } from './components/lead-form/third-pillar-form/third-pillar-form.component';
import { TravelFormComponent } from './components/lead-form/travel-form/travel-form.component';
import { ValuableObjectsFormComponent } from './components/lead-form/valuable-objects-form/valuable-objects-form.component';
import { VehicleFormComponent } from './components/lead-form/vehicle-form/vehicle-form.component';
import { AddLeadFormComponent } from './components/add-lead-form/add-lead-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CancellationFormComponent } from './components/lead-form/cancellation-form/cancellation-form.component';
import { CommissionBaseListComponent } from './components/commission-base-list/commission-base-list.component';
import { AssignProductsModalComponent } from './components/assign-products-modal/assign-products-modal.component';
import { AgGridModule } from 'ag-grid-angular';
import { DuplicateLeadComponent } from './components/duplicate-lead/duplicate-lead.component';
export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    SelectAllCheckboxComponent,
    FileDragDropDirective,
    SafeHtmlPipe,
    customerFilter,
    SplitPipe,
    ReplaceUnderscorePipe,
    LanguageSwitcherComponent,
    CustomerNavComponent,
    DataAnalysisNavComponent,
    SelectAllMatCheckboxComponent,
    PdfViewerModalComponent,
    GenerateUserPasswordModalComponent,
    UpdateDocumentModalComponent,
    DocumentRequestModalComponent,
    DocumentUploadModalComponent,
    AddLeadFormComponent,
    LeadFormComponent,
    HealthFormComponent,
    TravelFormComponent,
    CentralisationFormComponent,
    RcHouseholdFormComponent,
    VehicleFormComponent,
    LegalProtectionFormComponent,
    BuildingFormComponent,
    ConstructionFormComponent,
    ThirdPillarFormComponent,
    AnimalFormComponent,
    ValuableObjectsFormComponent,
    CancellationFormComponent,
    CommissionBaseListComponent,
    AssignProductsModalComponent,
    DuplicateLeadComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MatSelectModule,
    MatCheckboxModule,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    AgGridModule,
  ],
  exports: [
    SelectAllCheckboxComponent,
    FileDragDropDirective,
    SafeHtmlPipe,
    LanguageSwitcherComponent,
    CustomerNavComponent,
    DataAnalysisNavComponent,
    ReplaceUnderscorePipe,
    SelectAllMatCheckboxComponent,
    customerFilter,
    SplitPipe,
    PdfViewerModalComponent,
    GenerateUserPasswordModalComponent,
    UpdateDocumentModalComponent,
    DocumentRequestModalComponent,
    DocumentUploadModalComponent,
    AddLeadFormComponent,
    CommissionBaseListComponent,
    DuplicateLeadComponent,
  ],
  providers: [
    DatePipe,
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation],
    },
  ],
})
export class SharedModule {}
